import React from "react"
import { graphql, Link } from "gatsby"
import { SiteMetadata } from "../../components"
import { Layout } from "../../layouts/Layout"
import Img from "gatsby-image"

import { Container, Row, Col } from "react-bootstrap"

export default ({ data }) => {
  return (
    <Layout>
        <SiteMetadata 
          title="Блог" 
          canonical="/blog/"
          description="Полезная информация и истории из мира пчеловодства. Все о меде, пчелах и с чем их едят." 
        />
        <Container className="mt-5">
          <Row>
            <Col className="text-center">
              <h1 className="d-inline-block mb-5 beeright-small">Блог Пчелиного Хуторка</h1>
            </Col>
          </Row>
        </Container>  
        <Container>
            <Row>
                <Col>
                    <div className="card-columns">
                        {data.posts.nodes.map((post, i) => (<Post {...post} key={`post_${i}`}/>))}
                    </div>
                </Col>
            </Row>
        </Container>
        
    </Layout>
  )
}

const Post = ({img, title, slug, shortdesc}) => {
    return(
        <div className="card">
            <Img fluid={img.childImageSharp.fluid} className="card-img-top" alt={title}/>
            <div className="card-body px-2 py-3 text-center">
                <Link to={`/blog/${slug}/`} className="stretched-link"><h3 className="card-title mb-0">{title}</h3></Link>
                <p className="card-text mt-3">{shortdesc}</p>
            </div>
        </div>
    )
}


export const query = graphql`
  query BlogIndexQuery {    
    posts: allBlogJson {
        nodes {
          shortdesc
          slug
          title
          img {
            childImageSharp {
              fluid(maxWidth: 500, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
        }
      }        
    }
  }
`
